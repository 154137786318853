<template>
  <screen-grid :full-height="true">
    <screen-card :full-height="true">
      <template v-slot:body>
        <LeadSalesRepFilters @onUpdateFilters="onUpdateFilters" />
        <div class="sales-leads-list__list">
          <LeadSalesRepMobileList
            :leads-mobile="leadsMobile"
            :is-loading="isLoading || isUpdatingData"
            :is-scrolling="isScrolling"
            :no-more-data="noMoreData"
            @dealerContacted="dealerContacted"
            @statusChanged="statusChanged"
            @goToLead="goToLead"
          />
        </div>
        <div class="sales-leads-list__table">
          <ui-data-table
            v-if="$vuetify.breakpoint.mdAndUp"
            :columns="columns"
            :rows="leads"
            :total-rows="totalRows"
            :is-loading="isLoading || isUpdatingData"
            :pagination="leadsPagination"
            @loadItems="
              loadLeads({ filters: stringifiedSearchFilters, from: date[0], to: date[1], ...$event }, true, true)
            "
            @onCellClick="goToLead"
          >
            <template slot="data-table-row" slot-scope="props">
              <router-link
                v-if="props.column.field !== 'actions'"
                class="sales-leads-list__table__link"
                :to="{
                  name: 'Lead',
                  params: { id: props.row['id'], locationId: props.row['locationId'], currentTab: 'general' },
                }"
              />
              <div v-if="props.column.field === 'createdAt'">{{ timeFromNow(props.row['createdAt'], true) }}</div>
              <div v-else-if="props.column.field === 'convertedStatus'">
                <v-chip :color="getLeadColor(props.row['convertedStatus'])" dark small>
                  {{ $tc(`leads.status.${props.row['convertedStatus'].toLowerCase()}`, 1) }}
                </v-chip>
              </div>
              <div v-else-if="props.column.field === 'price'">
                {{ numberFormat({ number: props.row['price'], style: 'currency', currency: props.row['currency'] }) }}
              </div>
              <div v-else-if="props.column.field === 'externalId'">
                <code>{{ props.row['externalId'] }}</code>
                <div class="text-truncate" style="max-width: 220px">{{ props.row['locationName'] }}</div>
              </div>
              <div v-else-if="props.column.field === 'productName'">
                <code>{{ props.row['productId'] }}</code>
                <div class="text-truncate" style="max-width: 220px">{{ props.row['productName'] }}</div>
              </div>
              <div v-else-if="props.column.field === 'email'">
                <div class="text-truncate" style="max-width: 200px">{{ props.row['email'] }}</div>
              </div>
              <div v-else-if="props.column.field === 'managementStatus'" class="tw-text-center">
                <v-icon v-if="props.row['managementStatus']" color="success">
                  {{ icons.mdiBellCheckOutline }}
                </v-icon>
                <v-icon v-else color="error">
                  {{ icons.mdiBellAlertOutline }}
                </v-icon>
              </div>
              <div v-else-if="props.column.field === 'actions'">
                <LeadSalesRepActions
                  :item="props.row"
                  :is-updating="updatingLead === props.row.id"
                  @dealerContacted="dealerContacted"
                  @statusChanged="statusChanged"
                />
              </div>
              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { onScroll } from '@/mixins/scroll.mixin'
import { getLeadColor } from '@/utils/colors.util'
import { timeFromNow } from '@/utils/hours.util'
import { numberFormat } from '@/utils/utilities.util'
import { userRoles } from '@/config/permissions.config'
import { isUserAuthorized } from '@/config/permissions.config'
import { mdiBellAlertOutline, mdiBellCheckOutline } from '@mdi/js'
import UiDataTable from '@/components/UI/DataTable.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import LeadSalesRepFilters from '@/components/Lead/SalesRep/Filters.vue'
import LeadSalesRepMobileList from '@/components/Lead/SalesRep/MobileList.vue'
import LeadSalesRepActions from '@/components/Lead/SalesRep/Actions.vue'

export default {
  name: 'SalesLeadsList',
  components: {
    UiDataTable,
    ScreenGrid,
    ScreenCard,
    LeadSalesRepFilters,
    LeadSalesRepMobileList,
    LeadSalesRepActions,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdatingData: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    updatingLead: {
      type: [Number, String],
      required: false,
      default: null,
    },
    totalRows: {
      type: Number,
      required: false,
      default: 0,
    },
    noMoreData: {
      type: Boolean,
      required: false,
      default: false,
    },
    leads: {
      type: Array,
      required: true,
    },
    leadsMobile: {
      type: Array,
      required: true,
    },
    leadsPagination: {
      type: Object,
      required: true,
    },
  },
  mixins: [onScroll],
  data() {
    return {
      stringifiedSearchFilters: '',
      search: '',
      date: [],
      userRoles,
      isUserAuthorized,
      getLeadColor,
      timeFromNow,
      numberFormat,
      icons: {
        mdiBellAlertOutline,
        mdiBellCheckOutline,
      },
    }
  },
  computed: {
    noData() {
      return this.isLoading || this.noMoreData
    },
    columns() {
      return [
        {
          label: this.$t('leads.salesRep.column.createdAt'),
          field: 'createdAt',
          width: '140px',
        },
        { label: this.$t('leads.salesRep.column.status'), field: 'convertedStatus', sortable: false },
        { label: this.$t('leads.salesRep.column.value'), field: 'price', sortable: false },
        { label: this.$t('leads.salesRep.column.locationName'), field: 'externalId', sortable: false },
        { label: this.$t('leads.salesRep.column.productName'), field: 'productName' },
        { label: this.$t('leads.salesRep.column.customer'), field: 'email', width: '200px' },
        {
          label: this.$t('leads.salesRep.column.dealerContacted'),
          field: 'managementStatus',
          sortable: false,
          thClass: 'tw-whitespace-nowrap',
        },
        { label: '', field: 'actions', sortable: false },
      ]
    },
  },
  methods: {
    onUpdateFilters(filters, search, date) {
      this.search = search
      this.date = date
      this.stringifiedSearchFilters = filters
      this.loadLeads({ filters: this.stringifiedSearchFilters, from: this.date[0], to: this.date[1] }, true, true)
    },
    goToLead(e) {
      this.$router.push({ name: 'Lead', params: { locationId: e.row.locationId, id: e.row.id } })
    },
    loadLeads(tableParams = null, resetLeads = false, updating = false) {
      this.$emit('loadLeads', {
        tableParams,
        resetLeads,
        updating,
        search: this.search,
      })
    },
    dealerContacted(item) {
      this.$emit('updateLead', item, {
        managementStatus: 'CALL',
        price: item.price,
      })
    },
    statusChanged(item, status) {
      this.$emit('updateLead', item, {
        convertedStatus: status,
        price: item.price,
      })
    },
  },
}
</script>

<style lang="scss">
.sales-leads-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__list,
  &__table {
    margin-top: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
    }
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
