<template>
  <div class="leads-dashboard-card">
    <div class="leads-dashboard-card__title">
      <skeleton-line v-if="isLoading" height="23px" width="200px" />
      <span class="leads-dashboard-card__title__text" v-else>{{ title }}</span>
      <v-popover
        class="leads-dashboard-card__title__selector"
        placement="bottom-end"
        popoverInnerClass="popover-inner no-overflow"
        v-if="hasSelector"
      >
        <ui-button
          button="secondary"
          icon="more_vert"
          :icon-only="true"
          label="button"
          class="leads-dashboard-card__title__selector__button"
        />
        <template slot="popover">
          <div class="leads-dashboard-card__title__selector__container">
            <div class="leads-dashboard-card__title__selector__container__title">
              {{ $t('leads.dashboard.stats.filters.title') }}
            </div>
            <ui-radio
              :id="filter.id"
              class="leads-dashboard-card__title__selector__container__choice"
              name="weekly-percent"
              :value="filter.value"
              align="space-between"
              v-model="defaultFilter"
              v-for="(filter, idx) in filters"
              :key="idx"
              v-close-popover
              @change="valueChanged"
            >
              {{ filter.label }}
            </ui-radio>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="leads-dashboard-card__data" :style="{ color }">
      <skeleton-line v-if="isLoading" height="50px" width="80px" />
      <span class="leads-dashboard-card__data__value" v-else>{{ value }}</span>
      <skeleton-line v-if="isLoading" height="20px" width="250px" margin-top="10px" />
      <span class="leads-dashboard-card__data__title" v-else>{{ valueTitle }}</span>
    </div>
    <skeleton-line v-if="isLoading" height="18px" width="150px" marginLeft="auto" margin-right="auto" />
    <div class="leads-dashboard-card__subdata" v-else>
      <span class="leads-dashboard-card__subdata__title">{{ subValueTitle }}</span>
      <span class="leads-dashboard-card__subdata__value">{{ parseInt(subValue) }}</span>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiRadio from '@/components/UI/Radio.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'

export default {
  name: 'LeadsDashboardCard',
  components: {
    UiButton,
    UiRadio,
    SkeletonLine,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    valueTitle: {
      type: String,
      required: true,
    },
    subValue: {
      type: [String, Number],
      required: true,
    },
    subValueTitle: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '#333',
    },
  },
  data() {
    return {
      defaultFilter: '5',
      filters: [
        { id: '5', value: '5', label: this.$t('leads.dashboard.stats.filters.percent5') },
        { id: '10', value: '10', label: this.$t('leads.dashboard.stats.filters.percent10') },
        { id: '15', value: '15', label: this.$t('leads.dashboard.stats.filters.percent15') },
      ],
    }
  },
  methods: {
    valueChanged() {
      this.$emit('overviewFilterChanged', this.defaultFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.leads-dashboard-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;

    &__text {
      font-size: $font-size-lg;
      font-weight: $font-weight-title;
    }

    &__selector {
      &__button {
        position: absolute;
        top: calc(-#{$button-min-height} / 4);
        right: calc(-#{$button-min-height} / 4);
      }
      &__container {
        padding: $gutter-desktop;

        &__title {
          font-size: $font-size-md;
          font-weight: $font-weight-title;
        }

        &__choice {
          padding: $gutter-mobile;
          padding-left: 0;
        }
      }
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile;

    &__title {
      text-align: center;
      font-size: $font-size-md;
    }

    &__value {
      font-size: $font-size-4xl;
      font-weight: $font-weight-title;
    }
  }

  &__subdata {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-top: auto;

    &__title {
      color: $grey-french;
    }
  }
}
</style>
