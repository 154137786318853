export const getLeadColor = status => {
  const s = status.toLowerCase()
  switch (s) {
    case 'processing':
      return '#166ed6'
    case 'pending':
      return '#e97018'
    case 'converted':
      return '#53b027'
    case 'lost':
      return '#d61717'
    default:
      return 'gray'
  }
}
