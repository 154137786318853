<template>
  <v-menu
    offset-y
    left
    :close-on-content-click="true"
    max-width="300"
    min-width="150"
    transition="slide-y-reverse-transition"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn
        v-if="item.locationPhone || isPendingOrAccepted"
        icon
        v-bind="attrs"
        v-on="{ ...menu }"
        :x-small="xSmall"
        :loading="isUpdating"
        @click.stop
      >
        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item v-if="item.locationPhone" link :href="`tel:${item.locationPhone}`">
        <v-list-item-icon class="tw-mr-4">
          <v-icon>{{ icons.mdiPhoneOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('leads.salesRep.actions.call', { number: item.locationPhone }) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isPendingOrAccepted && !item.managementStatus" link @click="$emit('dealerContacted', item)">
        <v-list-item-icon class="tw-mr-4">
          <v-icon>{{ icons.mdiAccountCheckOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('leads.salesRep.actions.dealerContacter') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="item.convertedStatus && item.convertedStatus !== 'CONVERTED'"
        link
        @click="$emit('statusChanged', item, 'CONVERTED')"
      >
        <v-list-item-icon class="tw-mr-4">
          <v-icon>{{ icons.mdiAccountCashOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('leads.salesRep.actions.moveToConverted') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isPendingOrAccepted" link @click="$emit('statusChanged', item, 'LOST')">
        <v-list-item-icon class="tw-mr-4">
          <v-icon>{{ icons.mdiAccountOffOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('leads.salesRep.actions.moveToLost') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiDotsVertical,
  mdiPhoneOutline,
  mdiAccountCashOutline,
  mdiAccountOffOutline,
  mdiAccountCheckOutline,
} from '@mdi/js'

export default {
  name: 'LeadSalesRepActions',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiDotsVertical,
      mdiPhoneOutline,
      mdiAccountCashOutline,
      mdiAccountOffOutline,
      mdiAccountCheckOutline,
    },
  }),
  computed: {
    isPendingOrAccepted() {
      return this.item.convertedStatus === 'PENDING' || this.item.convertedStatus === 'PROCESSING'
    },
  },
}
</script>
