<template>
  <div class="lead-sales-rep-dealer" :class="[`lead-sales-rep-dealer--${kpi.name}`]" @click="setCurrent(kpi.name)">
    <div class="lead-sales-rep-dealer__infos">
      <div class="lead-sales-rep-dealer__infos__title">
        {{ $t(`leads.salesRep.dealer.block.${kpi.name}`) }}
      </div>
      <div class="lead-sales-rep-dealer__infos__value">
        {{ numberFormat({ number: kpi.dealers }) }}
      </div>
    </div>
    <v-icon class="lead-sales-rep-dealer__icon">{{ icons.mdiArrowRight }}</v-icon>
  </div>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'
import { numberFormat } from '@/utils/utilities.util'

export default {
  name: 'LeadSalesRepDealer',
  props: {
    kpi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiArrowRight,
      },
      numberFormat,
    }
  },
  methods: {
    setCurrent(name) {
      this.$emit('click', name)
    },
  },
}
</script>

<style lang="scss">
.lead-sales-rep-dealer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color $transition-duration-very-fast $transition-effect-default;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  cursor: pointer;
  padding: 1rem;

  &--pending {
    border-color: $status-pending;
    color: $status-pending;
  }

  &--processing {
    border-color: $status-accepted;
    color: $status-accepted;
  }

  &--converted {
    border-color: $status-converted;
    color: $status-converted;
  }

  &--lost {
    border-color: $status-lost;
    color: $status-lost;
  }

  &--selected {
    color: white;

    &.lead-sales-rep-dealer--pending {
      background-color: $status-pending;
    }

    &.lead-sales-rep-dealer--processing {
      background-color: $status-accepted;
    }

    &.lead-sales-rep-dealer--converted {
      background-color: $status-converted;
    }

    &.lead-sales-rep-dealer--lost {
      background-color: $status-lost;
    }
  }

  &__infos {
    &__title {
      text-transform: uppercase;
      font-weight: bold;
    }

    &__value {
      margin-top: 0.5rem;
      line-height: 1;
      font-size: 2rem;
      font-weight: bold;

      &__quantity {
        opacity: 0.7;
        margin-left: 0.5rem;
        font-size: 1rem;
      }
    }
  }

  &__icon {
    transition: transform $transition-duration-very-fast $transition-effect-default;

    .lead-sales-rep-dealer--pending & {
      color: $status-pending;
    }

    .lead-sales-rep-dealer--processing & {
      color: $status-accepted;
    }

    .lead-sales-rep-dealer--converted & {
      color: $status-converted;
    }

    .lead-sales-rep-dealer--lost & {
      color: $status-lost;
    }

    .lead-sales-rep-dealer--selected & {
      transform: rotate(90deg);
      color: white;
    }
  }
}
</style>
