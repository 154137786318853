<template>
  <screen-grid :full-height="true">
    <screen-card :full-height="true">
      <template v-slot:body>
        <div class="sales-dealer__title">
          {{
            $tc('leads.dealersList.title', totalRows, {
              count: totalRows,
              status: $tc(`leads.status.${status}`, 2).toLowerCase(),
            })
          }}
        </div>
        <LeadSalesRepFilters :with-search="false" @onUpdateFilters="onUpdateFilters" />
        <div class="sales-dealer__list">
          <ui-data-list
            :items="leadsMobile"
            :is-loading="isLoading || isUpdatingData"
            :is-scrolling="isScrolling"
            :no-more-data="noMoreData"
            @click="goToLeads({ row: { locationId: $event.locationId } })"
            @loadItems="
              loadLeads(
                {
                  filters: stringifiedSearchFilters,
                  page: leadsPagination.page + 1,
                  from: date[0],
                  to: date[1],
                  ...$event,
                },
                false,
                true
              )
            "
          >
            <template v-slot:skeleton>
              <div class="sales-dealer__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
              <skeleton-icon margin-left="16px" />
            </template>
            <template v-slot:item="slotProps">
              <div class="sales-dealer__list__content">
                <div class="sales-dealer__list__content__location">
                  <code>{{ slotProps.item.externalId }}</code>
                  {{ slotProps.item.locationName }}
                </div>
                <div class="sales-dealer__list__content__amount">
                  {{
                    $tc('leads.dealersList.nbLeads', slotProps.item.quantity, {
                      quantity: slotProps.item.quantity,
                      status: $tc(`leads.status.${status}`, slotProps.item.quantity).toLowerCase(),
                    })
                  }}
                </div>
              </div>
              <div class="sales-dealer__list__actions">
                <div class="sales-dealer__list__actions__quantity">
                  {{
                    numberFormat({
                      number: slotProps.item.amount,
                      style: 'currency',
                      currency: slotProps.item.currency,
                    })
                  }}
                </div>
                <LeadSalesRepActions :item="slotProps.item" x-small />
              </div>
            </template>
          </ui-data-list>
        </div>
        <div class="sales-dealer__table">
          <ui-data-table
            :columns="columns"
            :rows="leads"
            :total-rows="totalRows"
            :is-loading="isLoading || isUpdatingData"
            :pagination="leadsPagination"
            @loadItems="
              loadLeads({ filters: stringifiedSearchFilters, from: date[0], to: date[1], ...$event }, false, true)
            "
            @onCellClick="goToLeads"
          >
            <template slot="data-table-row" slot-scope="props">
              <div v-if="props.column.field === 'externalId'">
                <code>{{ props.row['externalId'] }}</code>
                <div class="text-truncate" style="max-width: 220px">{{ props.row['locationName'] }}</div>
              </div>
              <div v-else-if="props.column.field === 'amount'">
                {{ numberFormat({ number: props.row['amount'], style: 'currency', currency: props.row['currency'] }) }}
              </div>
              <div v-else-if="props.column.field === 'actions'">
                <LeadSalesRepActions :item="props.row" />
              </div>
              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { onScroll } from '@/mixins/scroll.mixin'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { userRoles } from '@/config/permissions.config'
import { numberFormat } from '@/utils/utilities.util'
import LeadSalesRepFilters from '@/components/Lead/SalesRep/Filters.vue'
import LeadSalesRepActions from '@/components/Lead/SalesRep/Actions.vue'

export default {
  name: 'LeadsSalesDealer',
  components: {
    UiDataTable,
    UiDataList,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
    LeadSalesRepFilters,
    LeadSalesRepActions,
  },
  mixins: [onScroll],
  data() {
    return {
      stringifiedSearchFilters: '',
      search: '',
      date: [],
      status: 'pending',
      userRoles,
      numberFormat,
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdatingData: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalRows: {
      type: Number,
      required: false,
      default: 0,
    },
    noMoreData: {
      type: Boolean,
      required: false,
      default: false,
    },
    leads: {
      type: Array,
      required: true,
    },
    leadsMobile: {
      type: Array,
      required: true,
    },
    leadsPagination: {
      type: Object,
      required: true,
    },
  },
  computed: {
    noData() {
      return this.isLoading || this.noMoreData
    },
    columns() {
      return [
        {
          label: this.$t('leads.salesRep.column.locationName'),
          field: 'externalId',
          sortable: false,
        },
        {
          label: this.$t('leads.salesRep.column.amount'),
          field: 'amount',
          sortable: false,
        },
        {
          label: this.$t('leads.salesRep.column.quantity'),
          field: 'quantity',
          sortable: false,
        },
        {
          label: '',
          field: 'actions',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    onUpdateFilters(filters, search, date, status) {
      this.search = search
      this.date = date
      this.status = status
      this.stringifiedSearchFilters = filters
      this.loadLeads({ filters: this.stringifiedSearchFilters, from: this.date[0], to: this.date[1] }, true, true)
    },
    loadLeads(tableParams = null, resetLeads = false, updating = false) {
      this.$emit('loadLeads', {
        tableParams,
        resetLeads,
        updating,
        search: this.search,
      })
    },
    goToLeads(e) {
      this.$router.push({
        name: 'Leads',
        params: { currentTab: 'list' },
        query: { locationId: e.row.locationId, status: this.status, date: this.date },
      })
    },
  },
}
</script>

<style lang="scss">
.sales-dealer {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__title {
    margin-bottom: $gutter-mobile;
    padding: 0 1rem;
    font-size: 1.125rem;
    font-weight: 600;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
      padding: 0;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__list,
  &__table {
    margin-top: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
    }
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__content {
      flex: 1;
      align-self: flex-start;

      &__location {
        font-weight: 600;
      }

      &__amount {
        @include text-ellipsis(1, 14px);

        margin-top: 0.5rem;
        font-size: $font-size-default;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }

    &__actions {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      align-self: stretch;
      justify-content: space-between;
      white-space: nowrap;

      &__quantity {
        font-size: 0.75rem;
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }
    &__status {
      border-radius: 50px;
      background-color: map-get($generic-color-variants, 'data');
      padding: 4px 8px;
      white-space: nowrap;
      color: white;
      font-size: 11px;
      font-weight: 300;

      &:after {
        margin-left: 5px;
        content: '⬤';
      }

      &--SATISFIED,
      &--REASSIGN,
      &--TMP_REASSIGN,
      &--DEALER_ACCEPTED,
      &--TMP_DEALER_ACCEPTED,
      &--DEALER_ACCEPTED_VIA_CUSTOMER {
        background-color: map-get($generic-color-variants, 'success');
      }

      &--UNSATISFIED,
      &--WONT_REASSIGN,
      &--TMP_WONT_REASSIGN,
      &--DEALER_REJECTED,
      &--TMP_DEALER_REJECTED,
      &--DEALER_REJECTED_VIA_CUSTOMER {
        background-color: map-get($generic-color-variants, 'error');
      }

      &--LEAD_CREATION {
        background-color: map-get($generic-color-variants, 'warning');
      }
    }
    &__block {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
