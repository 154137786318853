var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leads-leads",on:{"&scroll":function($event){return _vm.onScroll($event.srcElement, _vm.tabsElement)}}},[_c('screen-header',{attrs:{"title":_vm.$t('leads.leads.title'),"breadcrumbs":_vm.breadcrumbs,"is-loading":_vm.isLoading && !_vm.isScrolling,"right-content":true}},[(_vm.currentTab === 'list')?[(_vm.isLoading)?_c('v-skeleton-loader',{staticClass:"tw-rounded-full",attrs:{"type":"image","width":"100","height":"44"}}):_c('v-btn',{staticClass:"tw-px-4",attrs:{"rounded":"","color":"primary","large":"","loading":_vm.isExportLeadsLoading},on:{"click":_vm.exportLeads}},[_c('v-icon',{staticClass:"tw-mr-2"},[_vm._v(_vm._s(_vm.icons.mdiDownload))]),_vm._v(" "+_vm._s(_vm.$t('leads.leads.export.cta'))+" ")],1)]:_vm._e()],2),_c('screen-container',{staticClass:"leads-leads__tabscontainer",attrs:{"direction":"column"}},[_c('screen-tabs',{attrs:{"tabs":_vm.tabs,"element-fixed":_vm.elementFixed,"current-tab":_vm.currentTab || 'dashboard',"is-loading":_vm.isLoading},on:{"mounted":_vm.onTabsMounted,"click":_vm.setCurrentTab}})],1),(_vm.currentTab === 'dashboard' || !_vm.currentTab)?_c('screen-container',[(_vm.isUserAuthorized(_vm.userRoles.groupAdmin))?_c('leads-sales-dashboard',{attrs:{"summary":_vm.dashboardSummary,"isLoading":_vm.isLoading,"is-updating-data":_vm.isUpdatingData,"updating-lead":_vm.updatingLead,"leads":_vm.leads},on:{"updateLead":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.updateLeadData.apply(void 0, args.concat( [true] ));
},"loadLeads":function($event){return _vm.loadLeadsAndSummary($event, true)}}}):_c('leads-dashboard',{attrs:{"graph":_vm.dashboardGraph,"summary":_vm.dashboardSummary,"overview":_vm.dashboardOverview,"network":_vm.dashboardNetwork,"isLoading":_vm.isLoading,"stats-filter":_vm.statsFilter},on:{"statsFilterChanged":_vm.updateDateRange,"networkFilterChanged":_vm.updateDateRangeNetwork,"overviewFilterChanged":_vm.updateOverviewGoal}})],1):_vm._e(),(_vm.currentTab === 'list')?_c('screen-container',{attrs:{"full-height":true}},[(_vm.isUserAuthorized(_vm.userRoles.groupAdmin))?_c('leads-sales-list',{attrs:{"leads":_vm.leads,"leads-mobile":_vm.leadsMobile,"leads-pagination":_vm.leadsPagination,"is-loading":_vm.isLoading,"is-updating-data":_vm.isUpdatingData,"is-scrolling":_vm.isScrolling,"updating-lead":_vm.updatingLead,"total-rows":_vm.totalRows,"no-more-data":_vm.noMoreData},on:{"updateLead":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.updateLeadData.apply(void 0, args.concat( [false] ));
},"loadLeads":_vm.loadLeads}}):_c('leads-list',{ref:"leadsList",attrs:{"leads":_vm.leads,"leads-mobile":_vm.leadsMobile,"leads-pagination":_vm.leadsPagination,"lead-locations":_vm.leadLocations,"is-loading":_vm.isLoading,"is-updating-data":_vm.isUpdatingData,"is-scrolling":_vm.isScrolling,"total-rows":_vm.totalRows,"no-more-data":_vm.noMoreData},on:{"loadLeads":_vm.loadLeadsAndFilters}})],1):_vm._e(),(_vm.currentTab === 'dealers' && _vm.isUserAuthorized(_vm.userRoles.groupAdmin))?_c('screen-container',{attrs:{"full-height":true}},[_c('leads-sales-dealer',{attrs:{"leads":_vm.dealers,"leads-mobile":_vm.dealersMobile,"leads-pagination":_vm.dealersPagination,"is-loading":_vm.isLoading,"is-updating-data":_vm.isUpdatingData,"is-scrolling":_vm.isScrolling,"total-rows":_vm.totalDealersRows,"no-more-data":_vm.noMoreData},on:{"loadLeads":_vm.loadDealers}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }