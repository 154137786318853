<template>
  <div class="leads-leads" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      :title="$t('leads.leads.title')"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
    >
      <template v-if="currentTab === 'list'">
        <v-skeleton-loader v-if="isLoading" type="image" width="100" height="44" class="tw-rounded-full" />

        <v-btn
          v-else
          rounded
          color="primary"
          large
          class="tw-px-4"
          :loading="isExportLeadsLoading"
          @click="exportLeads"
        >
          <v-icon class="tw-mr-2">{{ icons.mdiDownload }}</v-icon>
          {{ $t('leads.leads.export.cta') }}
        </v-btn>
      </template>
    </screen-header>
    <screen-container direction="column" class="leads-leads__tabscontainer">
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab || 'dashboard'"
        :is-loading="isLoading"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />
    </screen-container>

    <!-- Dashboard Section -->
    <screen-container v-if="currentTab === 'dashboard' || !currentTab">
      <leads-sales-dashboard
        :summary="dashboardSummary"
        :isLoading="isLoading"
        :is-updating-data="isUpdatingData"
        :updating-lead="updatingLead"
        :leads="leads"
        v-if="isUserAuthorized(userRoles.groupAdmin)"
        @updateLead="(...args) => updateLeadData(...args, true)"
        @loadLeads="loadLeadsAndSummary($event, true)"
      />
      <leads-dashboard
        :graph="dashboardGraph"
        :summary="dashboardSummary"
        :overview="dashboardOverview"
        :network="dashboardNetwork"
        :isLoading="isLoading"
        :stats-filter="statsFilter"
        v-else
        @statsFilterChanged="updateDateRange"
        @networkFilterChanged="updateDateRangeNetwork"
        @overviewFilterChanged="updateOverviewGoal"
      />
    </screen-container>

    <!-- List Section -->
    <screen-container :full-height="true" v-if="currentTab === 'list'">
      <leads-sales-list
        v-if="isUserAuthorized(userRoles.groupAdmin)"
        :leads="leads"
        :leads-mobile="leadsMobile"
        :leads-pagination="leadsPagination"
        :is-loading="isLoading"
        :is-updating-data="isUpdatingData"
        :is-scrolling="isScrolling"
        :updating-lead="updatingLead"
        :total-rows="totalRows"
        :no-more-data="noMoreData"
        @updateLead="(...args) => updateLeadData(...args, false)"
        @loadLeads="loadLeads"
      />
      <leads-list
        v-else
        ref="leadsList"
        :leads="leads"
        :leads-mobile="leadsMobile"
        :leads-pagination="leadsPagination"
        :lead-locations="leadLocations"
        :is-loading="isLoading"
        :is-updating-data="isUpdatingData"
        :is-scrolling="isScrolling"
        :total-rows="totalRows"
        :no-more-data="noMoreData"
        @loadLeads="loadLeadsAndFilters"
      />
    </screen-container>

    <!-- Dealers Section -->
    <screen-container :full-height="true" v-if="currentTab === 'dealers' && isUserAuthorized(userRoles.groupAdmin)">
      <leads-sales-dealer
        :leads="dealers"
        :leads-mobile="dealersMobile"
        :leads-pagination="dealersPagination"
        :is-loading="isLoading"
        :is-updating-data="isUpdatingData"
        :is-scrolling="isScrolling"
        :total-rows="totalDealersRows"
        :no-more-data="noMoreData"
        @loadLeads="loadDealers"
      />
    </screen-container>
  </div>
</template>

<script>
import { mdiDownload } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { notif } from '@/mixins/notification.mixin'
import { onScroll } from '@/mixins/scroll.mixin'
import { userRoles } from '@/config/permissions.config'
import { isUserAuthorized } from '@/config/permissions.config'
import { downloadFile } from '@/utils/download.util'
import { formatedDate } from '@/utils/date.util'

import LeadsDashboard from './Dashboard.vue'
import LeadsSalesDashboard from './SalesDashboard.vue'
import LeadsList from './List.vue'
import LeadsSalesDealer from './SalesDealer.vue'
import LeadsSalesList from './SalesList.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'

export default {
  name: 'Leads',
  components: {
    LeadsDashboard,
    LeadsList,
    LeadsSalesDashboard,
    LeadsSalesDealer,
    LeadsSalesList,
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isExportLeadsLoading: false,
      isUpdatingData: false,
      isScrolling: false,
      updatingLead: null,
      totalRows: 0,
      totalDealersRows: 0,
      noMoreData: false,
      userRoles,
      isUserAuthorized,
      tabsElement: null,
      overviewGoal: '5',
      params: null,
      statsFilter: {
        id: 'last14',
        type: 'day',
        label: this.$t('leads.dashboard.filters.last14'),
      },
      icons: {
        mdiDownload,
      },
    }
  },
  async created() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('dashboard')
    }

    if (!isUserAuthorized(userRoles.groupAdmin)) {
      this.isLoading = true
      await this.loadStats()
      await this.loadNetwork()
      this.isLoading = false
    }
  },
  computed: {
    ...mapState({
      leads: state => state.lead.leads,
      leadsMobile: state => state.lead.leadsMobile,
      leadsPagination: state => state.lead.leadsPagination,
      dealers: state => state.lead.dealers,
      dealersMobile: state => state.lead.dealersMobile,
      dealersPagination: state => state.lead.dealersPagination,
      dashboardGraph: state => state.lead.dashboardGraph,
      dashboardSummary: state => state.lead.dashboardSummary,
      dashboardOverview: state => state.lead.dashboardOverview,
      dashboardNetwork: state => state.lead.dashboardNetwork,
      leadLocations: state => state.lead.leadLocations,
    }),
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('leads.leads.breadcrumb'),
          route: {
            name: 'UserExpress',
          },
        },
      ]
    },
    tabs() {
      const tabs = [
        {
          label: this.$t('leads.leads.tabs.dashboard'),
          key: 'dashboard',
        },
        {
          label: this.$t('leads.leads.tabs.list'),
          key: 'list',
        },
      ]
      if (isUserAuthorized(userRoles.groupAdmin)) {
        tabs.push({
          label: this.$t('leads.leads.tabs.dealers'),
          key: 'dealers',
        })
      }
      return tabs
    },
    userExpressAuthorized() {
      return this.userExpress.filter(user => {
        if (user.status === '1') {
          return user
        }
      })
    },
  },
  methods: {
    ...mapActions({
      getLeads: 'lead/getLeads',
      getExportLeads: 'lead/getExportLeads',
      getDealers: 'lead/getDealers',
      getGraph: 'lead/getGraph',
      getSummary: 'lead/getSummary',
      getGroupSummary: 'lead/getGroupSummary',
      getOverview: 'lead/getOverview',
      getNetwork: 'lead/getNetwork',
      updateLead: 'lead/updateLead',
      getLeadLocations: 'lead/getLeadLocations',
    }),
    async exportLeads() {
      const { to, from } = this.$refs.leadsList.queryParams
      const search = this.$refs.leadsList.stringifiedSearchFilters
      this.isExportLeadsLoading = true
      const file = await this.getExportLeads({ params: { to, from }, search })
      downloadFile(file, `export_leads_${formatedDate('now', 'DDMMYYYY')}.csv`)

      this.isExportLeadsLoading = false
    },
    updateDateRange(e) {
      this.statsFilter = e
      this.loadStats()
    },
    updateOverviewGoal(e) {
      this.overviewGoal = e
      this.loadOverview()
    },
    updateDateRangeNetwork(e) {
      this.statsFilter = e
      this.loadNetwork()
    },
    async loadStats() {
      await this.getGraph({ view: this.statsFilter.type, dateRange: this.statsFilter.id })
      await this.getSummary({ dateRange: this.statsFilter.id })
      await this.getOverview({ dateRange: this.statsFilter.id, goal: this.overviewGoal })
    },
    async loadOverview() {
      await this.getOverview({ dateRange: this.statsFilter.id, goal: this.overviewGoal })
    },
    async loadNetwork() {
      await this.getNetwork({ dateRange: this.statsFilter.id })
    },
    async loadLeads(e) {
      this.params = e
      if (e.updating) {
        this.isUpdatingData = true
      } else {
        this.isLoading = true
      }
      await this.getLeads({ tableParams: e.tableParams, resetLeads: e.resetLeads, search: e.search })
      this.setLeadsParams()
    },
    async loadLeadsAndSummary(e, refreshSummary) {
      this.params = e
      if (e.updating) {
        this.isUpdatingData = true
      } else {
        this.isLoading = true
      }
      const promises = [this.getLeads({ tableParams: e.tableParams, resetLeads: e.resetLeads, search: e.search })]
      if (refreshSummary) {
        promises.push(this.getGroupSummary({ tableParams: { from: e.tableParams.from, to: e.tableParams.to } }))
      }
      await Promise.all(promises)
      this.setLeadsParams()
    },
    async loadLeadsAndFilters(e) {
      await Promise.all([this.loadLeads(e), this.getLeadLocations()])
    },
    async loadDealers(e) {
      if (e.updating) {
        this.isUpdatingData = true
      } else {
        this.isLoading = true
      }
      await this.getDealers({ tableParams: e.tableParams, resetLeads: e.resetLeads, search: e.search })
      this.setDealersParams()
    },
    setLeadsParams() {
      if (this.leads.length > 0) {
        this.totalRows = this.leadsPagination.nbResults
      } else {
        this.totalRows = 0
        this.noMoreData = true
      }
      this.isLoading = false
      this.isScrolling = false
      this.isUpdatingData = false
    },
    setDealersParams() {
      if (this.dealers.length > 0) {
        this.totalDealersRows = this.dealersPagination.nbResults
      } else {
        this.totalDealersRows = 0
        this.noMoreData = true
      }
      this.isLoading = false
      this.isScrolling = false
      this.isUpdatingData = false
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      this.params = null
      this.$router.replace({ ...this.$route.name, params: { ...this.$route.params, currentTab: tab } })
    },
    async updateLeadData(item, data, refreshSummary) {
      this.updatingLead = item.id
      try {
        await this.updateLead({ locationId: item.locationId, leadId: item.id, data })
        await this.loadLeadsAndSummary(this.params, refreshSummary)
        this.updatingLead = null
        this.notificationSuccess('leads')
      } catch {
        this.updatingLead = null
        this.notificationError()
      }
    },
  },
}
</script>

<style lang="scss">
.leads-leads {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__tabscontainer {
    padding-bottom: 0 !important;
  }
}
</style>
