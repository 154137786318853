<template>
  <ui-data-list
    :items="leadsMobile"
    :is-loading="isLoading"
    :is-scrolling="isScrolling"
    :no-more-data="noMoreData"
    @click="goToLead({ row: { locationId: $event.locationId, id: $event.id } })"
    class="lead-sales-rep-mobile-list"
  >
    <template v-slot:skeleton>
      <div class="lead-sales-rep-mobile-list__content">
        <skeleton-line height="18px" width="80%" />
        <skeleton-line height="15px" width="50%" margin-top="3px" />
      </div>
      <skeleton-icon margin-left="16px" />
    </template>
    <template v-slot:item="slotProps">
      <div class="lead-sales-rep-mobile-list__details">
        <div
          class="lead-sales-rep-mobile-list__details__status"
          :style="{ backgroundColor: getLeadColor(slotProps.item.convertedStatus) }"
        ></div>
        <v-icon v-if="slotProps.item.managementStatus" color="success" small>
          {{ icons.mdiBellCheckOutline }}
        </v-icon>
        <v-icon v-else color="error" small>
          {{ icons.mdiBellAlertOutline }}
        </v-icon>
      </div>
      <div class="lead-sales-rep-mobile-list__content">
        <div class="lead-sales-rep-mobile-list__content__location">
          <code>{{ slotProps.item.externalId }}</code>
          <span class="lead-sales-rep-mobile-list__content__location__name">{{ slotProps.item.locationName }}</span>
        </div>
        <div class="lead-sales-rep-mobile-list__content__product">
          <code>{{ slotProps.item.productId }}</code>
          {{ slotProps.item.productName }}
        </div>
        <div class="lead-sales-rep-mobile-list__content__price">
          {{ numberFormat({ number: slotProps.item.price, style: 'currency', currency: slotProps.item.currency }) }}
        </div>
      </div>
      <div class="lead-sales-rep-mobile-list__actions">
        <div class="lead-sales-rep-mobile-list__actions__date">
          {{ date(slotProps.item.createdAt, 'L') }}
        </div>
        <LeadSalesRepActions
          :item="slotProps.item"
          x-small
          @dealerContacted="dealerContacted"
          @statusChanged="statusChanged"
        />
      </div>
    </template>
  </ui-data-list>
</template>

<script>
import { mdiDotsVertical, mdiCheck, mdiBellAlertOutline, mdiBellCheckOutline } from '@mdi/js'
import { formatedDate } from '@/utils/date.util'
import { getLeadColor } from '@/utils/colors.util'
import { numberFormat } from '@/utils/utilities.util'
import UiDataList from '@/components/UI/DataList.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import LeadSalesRepActions from '@/components/Lead/SalesRep/Actions.vue'

export default {
  name: 'LeadSalesRepMobileList',
  components: {
    UiDataList,
    SkeletonLine,
    SkeletonIcon,
    LeadSalesRepActions,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    noMoreData: {
      type: Boolean,
      required: false,
      default: false,
    },
    leadsMobile: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiDotsVertical,
      mdiCheck,
      mdiBellAlertOutline,
      mdiBellCheckOutline,
    },
    getLeadColor,
    numberFormat,
  }),
  methods: {
    goToLead(e) {
      this.$emit('goToLead', e)
    },
    date(date, format = 'L LT') {
      return formatedDate(date, format, this.$i18n.locale)
    },
    dealerContacted(item) {
      this.$emit('dealerContacted', item)
    },
    statusChanged(item, status) {
      this.$emit('statusChanged', item, status)
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-sales-rep-mobile-list {
  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    align-self: flex-start;
    margin-top: 4px;
    margin-right: 0.5rem;

    &__status {
      margin-right: 0;
      border-radius: 50%;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &__content {
    flex: 1;
    align-self: flex-start;

    &__location {
      display: flex;
      gap: 2px;
      font-weight: 600;

      &__name {
        @include text-ellipsis(1, 14px);
      }
    }

    &__product {
      @include text-ellipsis(1, 14px);

      margin-top: 0.5rem;
      font-size: $font-size-default;
      font-style: normal;

      @media (min-width: $screen-sm) {
        @include text-ellipsis(1, 14px);

        margin-right: $gutter-tablet;
        font-size: $font-size-default;
      }
    }

    &__price {
      margin-top: 0.5rem;
      font-weight: 500;
    }
  }

  &__actions {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    justify-content: space-between;
    margin-left: 0.5rem;
    white-space: nowrap;

    &__date {
      font-size: 0.75rem;
    }
  }
}
</style>
