<template>
  <div class="lead-sales-rep-filters" :class="{ 'lead-sales-rep-filters--no-padding': dateOnly }">
    <template v-if="!dateOnly">
      <div class="lead-sales-rep-filters__inputs" :class="{ 'lead-sales-rep-filters__inputs--expanded': expanded }">
        <div v-if="!dateOnly" class="lead-sales-rep-filters__inputs__line">
          <v-select
            v-model="filters.status"
            id="status"
            solo
            flat
            outlined
            :items="statuses"
            item-text="label"
            item-value="value"
            :placeholder="$t('leads.salesRep.filters.status')"
            hide-details
            dense
            height="44px"
            class="lead-sales-rep-filters__inputs__status"
          >
            <template v-slot:selection="{ item }">
              <v-badge :color="getLeadColor(item.value)" dot inline left>
                <span class="tw-ml-2">{{ item.label }}</span>
              </v-badge>
            </template>
            <template v-slot:item="{ item }">
              <v-badge :color="getLeadColor(item.value)" dot inline left>
                <span class="tw-ml-2">{{ item.label }}</span>
              </v-badge>
            </template>
          </v-select>
          <LeadSalesRepDateMenu v-if="$vuetify.breakpoint.smAndDown" :periods="periods" @setPeriod="setPeriod" />
        </div>
        <v-autocomplete
          v-if="!dateOnly"
          v-model="filters.locations"
          class="lead-sales-rep-filters__inputs__locations"
          :items="locations"
          :loading="isLoading"
          :search-input.sync="locationSearch"
          cache-items
          hide-no-data
          :item-text="item => `${item.name} [${item.externalId}]`"
          item-value="id"
          :label="$t('leads.salesRep.fields.locations.label')"
          :placeholder="$t('leads.salesRep.fields.locations.placeholder')"
          hide-details
          solo
          flat
          outlined
          dense
          clearable
          multiple
          deletable-chips
          chips
          small-chips
          return-object
          color="primary"
        >
          <template v-slot:selection="{ item }">
            <v-chip small color="primary" close @click:close="removeLocation(item)">
              <span class="text-truncate">{{ item.name }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
        <v-text-field
          v-if="withSearch && !dateOnly"
          class="lead-sales-rep-filters__inputs__search"
          :value="filters.search"
          solo
          flat
          outlined
          dense
          height="44px"
          hide-details
          :placeholder="$t('leads.salesRep.fields.product.placeholder')"
          @input="onSearch"
        />
        <LeadSalesRepDateMenu v-if="$vuetify.breakpoint.mdAndUp" :periods="periods" @setPeriod="setPeriod" />
      </div>
      <div class="lead-sales-rep-filters__toggle" @click="expanded = !expanded">
        <span v-if="!expanded">{{ $t('common.button.showMoreFilters') }}</span>
        <span v-else>{{ $t('common.button.showLessFilters') }}</span>
      </div>
      <div class="lead-sales-rep-filters__date">
        <v-chip
          v-if="filters.date && filters.date.length > 1"
          close
          @click:close="filters.date = []"
          color="primary"
          small
        >
          {{ $t('common.date.from') }} {{ formatedDate(filters.date[0], 'L') }} {{ $t('common.date.to') }}
          {{ formatedDate(filters.date[1], 'L') }}
        </v-chip>
      </div>
    </template>
    <template v-else>
      <v-chip
        v-if="filters.date && filters.date.length > 1"
        class="sm:tw-mr-2 lead-sales-rep-filters__chip"
        close
        color="primary"
        small
        @click:close="filters.date = []"
      >
        {{ $t('common.date.from') }} {{ formatedDate(filters.date[0], 'L') }} {{ $t('common.date.to') }}
        {{ formatedDate(filters.date[1], 'L') }}
      </v-chip>
      <LeadSalesRepDateMenu :periods="periods" @setPeriod="setPeriod" />
    </template>
    <v-dialog ref="dialog" v-model="customDateModal" width="290px">
      <v-date-picker v-model="tmpDate" scrollable range :max="formatedDate('now', 'YYYY-MM-DD')">
        <v-spacer />
        <v-btn text rounded @click="customDateModal = false"> {{ $t('common.button.cancel') }} </v-btn>
        <v-btn text color="primary" rounded @click="setCustomDate"> {{ $t('common.button.ok') }} </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { debounce } from '@/utils/func.util'
import { formatedDate } from '@/utils/date.util'
import { getLeadColor } from '@/utils/colors.util'
import dayjs from 'dayjs'
import LeadSalesRepDateMenu from '@/components/Lead/SalesRep/DateMenu.vue'

const DATE_FORMAT = 'YYYY-MM-DD'
const stringStatus = status => (status ? `convertedStatus:${status.toUpperCase()}` : '')
const stringLocations = locations =>
  locations && locations.length ? `locationId:(${locations.map(l => l.id).join(';')})` : ''

export default {
  name: 'LeadSalesRepFilters',
  components: {
    LeadSalesRepDateMenu,
  },
  props: {
    withSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    dateOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    filters: {
      status: '',
      locations: [],
      search: null,
      date: [],
    },
    isLoading: false,
    locationSearch: null,
    tmpDate: null,
    customDateModal: false,
    formatedDate,
    expanded: false,
    getLeadColor,
  }),
  async mounted() {
    !this.locations.length && this.loadLocations({ filters: 'status:1' })
    const query = this.$route.query
    if (query.locationId) {
      await this.loadLocations({ filters: `id:${query.locationId}` })
      this.filters.locations.push(this.locations[0])
    }
    this.filters.date = query.date ? query.date : []
    this.filters.status = query.status ? query.status : 'pending'
  },
  watch: {
    locationSearch: debounce(async function () {
      // Items have already been requested
      if (this.isLoading) return
      this.loadLocations()
    }, 300),
    filters: {
      immediate: false,
      deep: true,
      handler: 'onUpdateFilters',
    },
  },
  computed: {
    ...mapState({
      locations: state => state.location.locations,
    }),
    stringifiedSearch() {
      return [stringStatus(this.filters.status), stringLocations(this.filters.locations)]
        .filter(filter => filter)
        .join(',')
    },
    statuses() {
      return [
        {
          label: this.$tc('leads.status.pending', 1),
          value: 'pending',
        },
        {
          label: this.$tc('leads.status.processing', 1),
          value: 'processing',
        },
        {
          label: this.$tc('leads.status.converted', 1),
          value: 'converted',
        },
        {
          label: this.$tc('leads.status.lost', 1),
          value: 'lost',
        },
      ]
    },
    periods() {
      return [
        { id: 'currentWeek', label: 'This week' },
        { id: 'last7Days', label: 'Last 7 days' },
        { id: 'currentMonth', label: 'This month' },
        { id: 'last30Days', label: 'Last 30 days' },
        { id: 'custom', label: 'Custom' },
      ]
    },
  },
  methods: {
    ...mapActions({
      getLocations: 'location/getLocations',
    }),
    onSearch: debounce(function (value) {
      this.filters.search = value
    }, 300),
    setCustomDate() {
      if (this.tmpDate && this.tmpDate.length === 2) {
        this.filters.date = this.tmpDate
        this.customDateModal = false
        this.tmpDate = null
      }
    },
    setPeriod(period) {
      switch (period) {
        case 'currentWeek':
          this.filters.date = [formatedDate(dayjs().day(1), DATE_FORMAT), formatedDate('now', DATE_FORMAT)]
          break
        case 'last7Days':
          this.filters.date = [formatedDate(dayjs().subtract(7, 'day'), DATE_FORMAT), formatedDate('now', DATE_FORMAT)]
          break
        case 'currentMonth':
          this.filters.date = [formatedDate(dayjs().startOf('month'), DATE_FORMAT), formatedDate('now', DATE_FORMAT)]
          break
        case 'last30Days':
          this.filters.date = [formatedDate(dayjs().subtract(30, 'day'), DATE_FORMAT), formatedDate('now', DATE_FORMAT)]
          break
        case 'custom':
          this.customDateModal = true
          break
      }
    },
    async loadLocations(tableParams = null) {
      this.isLoading = true
      await this.getLocations({
        tableParams: tableParams,
        resetLocations: true,
        search: this.locationSearch,
      })
      this.isLoading = false
    },
    onUpdateFilters() {
      this.$emit('onUpdateFilters', this.stringifiedSearch, this.filters.search, this.filters.date, this.filters.status)
    },
    removeLocation(item) {
      this.filters.locations = this.filters.locations.filter(l => l.id !== item.id)
    },
  },
}
</script>

<style lang="scss">
.lead-sales-rep-filters {
  position: relative;
  padding: 0 1rem;

  @media (min-width: $screen-sm) {
    padding: 0;
  }

  &--no-padding {
    padding: 0;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: $gutter-mobile;
    height: $button-min-height;
    overflow: hidden;

    &--expanded {
      height: initial;
    }

    @media (min-width: $screen-md) {
      flex-direction: row;
      gap: $gutter-tablet;
      align-items: center;
      height: initial;
      overflow: initial;
    }

    @media (min-width: $screen-xl) {
      gap: $gutter-desktop;
    }

    &__line {
      display: flex;
      gap: $gutter-mobile;
      align-items: center;
    }

    &__locations {
      .v-input__slot {
        min-height: $button-min-height !important;
      }
    }

    &__status,
    &__search {
      @media (min-width: $screen-md) {
        width: 200px;
        min-width: 200px;
      }
    }
  }

  &__toggle {
    margin-top: $gutter-mobile;
    text-align: center;
    text-decoration: underline;
    font-weight: 500;

    @media (min-width: $screen-md) {
      display: none;
    }
  }

  &__date {
    margin-top: 0.5rem;
    text-align: center;

    @media (min-width: $screen-md) {
      text-align: initial;
    }
  }

  &__chip {
    position: absolute !important;
    top: 100%;
    right: 0;
    margin-top: 4px;
    max-width: initial !important;

    @media (min-width: $screen-sm) {
      position: initial !important;
    }
  }
}
</style>
