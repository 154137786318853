<template>
  <screen-grid class="leads-dashboard">
    <!-- Stats -->
    <screen-card :title="$t('leads.dashboard.summary.title')" :isLoading="isLoading">
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="44px" width="130px" />
        <ui-dropdown
          class="leads-dashboard__filter"
          v-else
          :value="statsFilter"
          track-by="id"
          label="label"
          :options="statsFilterOptions"
          :placeholder="$t('leads.dashboard.filters.placeholder')"
          @input="emitStatsFilterChange"
        />
      </template>
      <template v-slot:body>
        <div class="leads-dashboard__stats">
          <div class="leads-dashboard__stats__KPIS">
            <skeleton-line v-if="isLoading" height="100%" width="100%" />
            <div class="leads-dashboard__stats__KPIS__block leads-dashboard__stats__KPIS__block--generated" v-else>
              <span class="leads-dashboard__stats__KPIS__block__number">
                {{
                  new Intl.NumberFormat($i18n.locale, {
                    style: 'currency',
                    currency: summary.TOTAL.currency,
                    notation: 'compact',
                    compactDisplay: 'short',
                  }).format(summary.TOTAL.amount)
                }}
              </span>
              <span class="leads-dashboard__stats__KPIS__block__title">
                {{ $tc('leads.dashboard.summary.overview.generated', summary.TOTAL.quantity) }}
              </span>
            </div>
            <skeleton-line v-if="isLoading" height="100%" width="100%" />
            <div class="leads-dashboard__stats__KPIS__block leads-dashboard__stats__KPIS__block--accepted" v-else>
              <span class="leads-dashboard__stats__KPIS__block__number">
                {{
                  new Intl.NumberFormat($i18n.locale, {
                    style: 'currency',
                    currency: summary.ACCEPTED.currency,
                    notation: 'compact',
                    compactDisplay: 'short',
                  }).format(summary.ACCEPTED.amount)
                }}
              </span>
              <span class="leads-dashboard__stats__KPIS__block__title">
                {{ $tc('leads.dashboard.summary.overview.accepted', summary.ACCEPTED.quantity) }}
              </span>
            </div>
            <skeleton-line v-if="isLoading" height="100%" width="100%" />
            <div class="leads-dashboard__stats__KPIS__block leads-dashboard__stats__KPIS__block--rejected" v-else>
              <span class="leads-dashboard__stats__KPIS__block__number">
                {{
                  new Intl.NumberFormat($i18n.locale, {
                    style: 'currency',
                    currency: summary.REJECTED.currency,
                    notation: 'compact',
                    compactDisplay: 'short',
                  }).format(summary.REJECTED.amount)
                }}
              </span>
              <span class="leads-dashboard__stats__KPIS__block__title">
                {{ $tc('leads.dashboard.summary.overview.rejected', summary.REJECTED.quantity) }}
              </span>
            </div>
            <skeleton-line v-if="isLoading" height="100%" width="100%" />
            <div class="leads-dashboard__stats__KPIS__block leads-dashboard__stats__KPIS__block--unprocessed" v-else>
              <span class="leads-dashboard__stats__KPIS__block__number">
                {{
                  new Intl.NumberFormat($i18n.locale, {
                    style: 'currency',
                    currency: summary.NO_ACTION.currency,
                    notation: 'compact',
                    compactDisplay: 'short',
                  }).format(summary.NO_ACTION.amount)
                }}
              </span>
              <span class="leads-dashboard__stats__KPIS__block__title">
                {{ $tc('leads.dashboard.summary.overview.unprocessed', summary.NO_ACTION.quantity) }}
              </span>
            </div>
          </div>
          <div class="leads-dashboard__stats__graph">
            <dashboard-graph :graph="graph" />
          </div>
        </div>
      </template>
    </screen-card>

    <!-- Value -->
    <screen-card ratio="1-3">
      <template v-slot:body>
        <dashboard-card
          :title="$t('leads.dashboard.stats.dealersEnrolment.title')"
          :value="
            $t('leads.dashboard.stats.dealersEnrolment.value', {
              value: Math.round(overview.current.percent * 10) / 10,
            })
          "
          :valueTitle="$t('leads.dashboard.stats.dealersEnrolment.valueTitle')"
          :subValue="overview.current.with_option"
          :subValueTitle="$tc('leads.dashboard.stats.dealersEnrolment.subvalueTitle', overview.current.with_option)"
          :isLoading="isLoading"
          color="#ff2b3a"
          v-if="hasOverviewCurrent"
        />
        <div class="leads-dashboard__overview__no-data" v-else>
          <span class="leads-dashboard__overview__no-data__title">{{
            $t('leads.dashboard.stats.dealersEnrolment.title')
          }}</span>
          <div class="leads-dashboard__overview__no-data__container">
            <span class="leads-dashboard__overview__no-data__container__message">{{
              $t('leads.dashboard.stats.noData')
            }}</span>
          </div>
        </div>
      </template>
    </screen-card>
    <screen-card ratio="1-3">
      <template v-slot:body>
        <dashboard-card
          :title="$t('leads.dashboard.stats.averageValue.title')"
          :value="
            new Intl.NumberFormat($i18n.locale, {
              style: 'currency',
              currency: overview.average.currency,
              notation: 'compact',
              compactDisplay: 'short',
            }).format(overview.average.avg)
          "
          :valueTitle="$t('leads.dashboard.stats.averageValue.valueTitle')"
          :subValue="overview.average.inactive"
          :subValueTitle="$tc('leads.dashboard.stats.averageValue.subvalueTitle', overview.average.inactive)"
          :isLoading="isLoading"
          color="#1d81fe"
          v-if="hasOverviewAverage"
        />
        <div class="leads-dashboard__overview__no-data" v-else>
          <span class="leads-dashboard__overview__no-data__title">{{
            $t('leads.dashboard.stats.averageValue.title')
          }}</span>
          <div class="leads-dashboard__overview__no-data__container">
            <span class="leads-dashboard__overview__no-data__container__message">{{
              $t('leads.dashboard.stats.noData')
            }}</span>
          </div>
        </div>
      </template>
    </screen-card>
    <screen-card ratio="1-3">
      <template v-slot:body>
        <dashboard-card
          :hasSelector="true"
          :title="$t('leads.dashboard.stats.estimatedValue.title')"
          :value="
            new Intl.NumberFormat($i18n.locale, {
              style: 'currency',
              currency: overview.weekly.currency,
              notation: 'compact',
              compactDisplay: 'short',
            }).format(overview.weekly.value)
          "
          :valueTitle="$tc('leads.dashboard.stats.estimatedValue.valueTitle', overviewGoal)"
          :subValue="overview.weekly.dealers"
          :subValueTitle="$tc('leads.dashboard.stats.estimatedValue.subvalueTitle', overview.weekly.dealers)"
          :isLoading="isLoading"
          color="#b648ff"
          @overviewFilterChanged="emitOverviewFilterChanged"
          v-if="hasOverviewWeekly"
        />
        <div class="leads-dashboard__overview__no-data" v-else>
          <span class="leads-dashboard__overview__no-data__title">{{
            $t('leads.dashboard.stats.estimatedValue.title')
          }}</span>
          <div class="leads-dashboard__overview__no-data__container">
            <span class="leads-dashboard__overview__no-data__container__message">{{
              $t('leads.dashboard.stats.noData')
            }}</span>
          </div>
        </div>
      </template>
    </screen-card>

    <!-- Lists #1 -->
    <screen-card :title="$t('leads.dashboard.network.title')" :isLoading="isLoading">
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="44px" width="130px" />
        <ui-dropdown
          class="leads-dashboard__filter"
          v-else
          :value="statsFilter"
          track-by="id"
          label="label"
          :options="statsFilterOptions"
          :placeholder="$t('leads.dashboard.filters.placeholder')"
          @input="emitNetworkFilterChange"
        />
      </template>
      <template v-slot:body>
        <div class="leads-dashboard__network">
          <div class="leads-dashboard__network__rank">
            <!-- TOP Business Maker -->
            <div class="leads-dashboard__network__rank__table">
              <skeleton-line v-if="isLoading" height="25px" width="200px" />
              <span
                class="leads-dashboard__network__rank__table__title leads-dashboard__network__rank__table__title--top-business"
                v-else
              >
                {{ $t('leads.dashboard.network.topBusiness') }}
              </span>
              <table v-if="hasNetworkTopBusinessMaker">
                <tbody>
                  <tr>
                    <th>{{ $t('leads.dashboard.network.label.rank') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.location') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.value') }}</th>
                  </tr>
                  <tr v-if="isLoading">
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                  </tr>
                  <tr v-for="(location, idx) in network.TOP_BUSINESS_MAKER" :key="idx" v-else>
                    <td class="top-business col-rank">
                      {{ idx + 1 }}
                    </td>
                    <td class="top-business col-name">
                      <router-link :to="{ name: 'Location', params: { id: location.locationId } }" target="_blank">
                        {{ location.locationName }}
                      </router-link>
                    </td>
                    <td class="top-business col-quantity">
                      {{
                        new Intl.NumberFormat($i18n.locale, {
                          style: 'currency',
                          currency: location.currency,
                          notation: 'compact',
                          compactDisplay: 'short',
                        }).format(location.amount)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="leads-dashboard__network__rank__table__no-data" v-else>
                {{ $t('leads.dashboard.network.noData') }}
              </div>
            </div>

            <!-- MOST Active Dealer -->
            <div class="leads-dashboard__network__rank__table">
              <skeleton-line v-if="isLoading" height="25px" width="200px" />
              <span
                class="leads-dashboard__network__rank__table__title leads-dashboard__network__rank__table__title--most-active"
                v-else
              >
                {{ $t('leads.dashboard.network.mostActive') }}
              </span>
              <table v-if="hasNetworkTopLocationAccepted">
                <tbody>
                  <tr>
                    <th>{{ $t('leads.dashboard.network.label.rank') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.location') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.quantity') }}</th>
                  </tr>
                  <tr v-if="isLoading">
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                  </tr>
                  <tr v-for="(location, idx) in network.TOP_LOCATION_ACCEPTED" :key="idx" v-else>
                    <td class="most-active col-rank">
                      {{ idx + 1 }}
                    </td>
                    <td class="most-active col-name">
                      <router-link :to="{ name: 'Location', params: { id: location.locationId } }" target="_blank">
                        {{ location.locationName }}
                      </router-link>
                    </td>
                    <td class="most-active col-quantity">
                      {{ location.cpt }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="leads-dashboard__network__rank__table__no-data" v-else>
                {{ $t('leads.dashboard.network.noData') }}
              </div>
            </div>

            <!-- MOST No Action Dealer -->
            <div class="leads-dashboard__network__rank__table">
              <skeleton-line v-if="isLoading" height="25px" width="200px" />
              <span
                class="leads-dashboard__network__rank__table__title leads-dashboard__network__rank__table__title--most-rejecting"
                v-else
              >
                {{ $t('leads.dashboard.network.mostRejecting') }}
              </span>
              <table v-if="hasNetworkMostIgnoringDealers">
                <tbody>
                  <tr>
                    <th>{{ $t('leads.dashboard.network.label.rank') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.location') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.quantity') }}</th>
                  </tr>
                  <tr v-if="isLoading">
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                  </tr>
                  <tr v-for="(location, idx) in network.MOST_IGNORING_DEALERS" :key="idx" v-else>
                    <td class="most-rejecting col-rank">
                      {{ idx + 1 }}
                    </td>
                    <td class="most-rejecting col-name">
                      <router-link :to="{ name: 'Location', params: { id: location.locationId } }" target="_blank">
                        {{ location.locationName }}
                      </router-link>
                    </td>
                    <td class="most-rejecting col-quantity">
                      {{ location.cpt }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="leads-dashboard__network__rank__table__no-data" v-else>
                {{ $t('leads.dashboard.network.noData') }}
              </div>
            </div>

            <!-- MOST Rejecting Dealers -->
            <div class="leads-dashboard__network__rank__table">
              <skeleton-line v-if="isLoading" height="25px" width="200px" />
              <span
                class="leads-dashboard__network__rank__table__title leads-dashboard__network__rank__table__title--most-ignoring"
                v-else
              >
                {{ $t('leads.dashboard.network.mostIgnoring') }}
              </span>
              <table v-if="hasNetworkTopLocationRejected">
                <tbody>
                  <tr>
                    <th>{{ $t('leads.dashboard.network.label.rank') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.location') }}</th>
                    <th>{{ $t('leads.dashboard.network.label.quantity') }}</th>
                  </tr>
                  <tr v-if="isLoading">
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                  </tr>
                  <tr v-for="(location, idx) in network.TOP_LOCATION_REJECTED" :key="idx" v-else>
                    <td class="most-ignoring col-rank">
                      {{ idx + 1 }}
                    </td>
                    <td class="most-ignoring col-name">
                      <router-link :to="{ name: 'Location', params: { id: location.locationId } }" target="_blank">
                        {{ location.locationName }}
                      </router-link>
                    </td>
                    <td class="most-ignoring col-quantity">
                      {{ location.cpt }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="leads-dashboard__network__rank__table__no-data" v-else>
                {{ $t('leads.dashboard.network.noData') }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </screen-card>

    <!-- Lists #2 -->
    <screen-card :title="$t('leads.dashboard.product.title')" :isLoading="isLoading">
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="44px" width="130px" />
        <ui-dropdown
          class="leads-dashboard__filter"
          v-else
          :value="statsFilter"
          track-by="id"
          label="label"
          :options="statsFilterOptions"
          :placeholder="$t('leads.dashboard.filters.placeholder')"
          @input="emitNetworkFilterChange"
        />
      </template>
      <template v-slot:body>
        <div class="leads-dashboard__product">
          <div class="leads-dashboard__product__rank">
            <!-- TOP Categories -->
            <div class="leads-dashboard__network__rank__table">
              <skeleton-line v-if="isLoading" height="25px" width="200px" />
              <span
                class="leads-dashboard__network__rank__table__title leads-dashboard__network__rank__table__title--top-categories"
                v-else
              >
                {{ $t('leads.dashboard.product.topCategories') }}
              </span>
              <table v-if="hasProductTopCategories">
                <tbody>
                  <tr>
                    <th>{{ $t('leads.dashboard.product.label.rank') }}</th>
                    <th>{{ $t('leads.dashboard.product.label.family') }}</th>
                    <th>{{ $t('leads.dashboard.product.label.value') }}</th>
                    <th>{{ $t('leads.dashboard.product.label.quantity') }}</th>
                  </tr>
                  <tr v-if="isLoading">
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                  </tr>
                  <tr v-for="(location, idx) in network.TOP_CATEGORIES" :key="idx" v-else>
                    <td class="top-categories col-rank">
                      {{ idx + 1 }}
                    </td>
                    <td class="top-categories">
                      {{ location.productFamily }}
                    </td>

                    <td class="top-categories">
                      {{
                        new Intl.NumberFormat($i18n.locale, {
                          style: 'currency',
                          currency: location.currency,
                          notation: 'compact',
                          compactDisplay: 'short',
                        }).format(location.amount)
                      }}
                    </td>
                    <td class="top-categories col-quantity">
                      {{ location.cpt }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="leads-dashboard__network__rank__table__no-data" v-else>
                {{ $t('leads.dashboard.product.noData') }}
              </div>
            </div>

            <!-- TOP Products -->
            <div class="leads-dashboard__network__rank__table">
              <skeleton-line v-if="isLoading" height="25px" width="200px" />
              <span
                class="leads-dashboard__network__rank__table__title leads-dashboard__network__rank__table__title--top-products"
                v-else
              >
                {{ $t('leads.dashboard.product.topProducts') }}
              </span>
              <table v-if="hasProductTopProductAccepted">
                <tbody>
                  <tr>
                    <th>{{ $t('leads.dashboard.product.label.rank') }}</th>
                    <th>{{ $t('leads.dashboard.product.label.productName') }}</th>
                    <th>{{ $t('leads.dashboard.product.label.productId') }}</th>
                    <th>{{ $t('leads.dashboard.product.label.value') }}</th>
                  </tr>
                  <tr v-if="isLoading">
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                    <td>
                      <skeleton-line v-if="isLoading" height="100%" width="100%" />
                    </td>
                  </tr>
                  <tr v-for="(location, idx) in network.TOP_PRODUCT_ACCEPTED" :key="idx" v-else>
                    <td class="top-products col-rank">
                      {{ idx + 1 }}
                    </td>
                    <td class="top-products">
                      {{ location.productName }}
                    </td>
                    <td class="top-products">
                      {{ location.productId }}
                    </td>
                    <td class="top-products">
                      {{
                        new Intl.NumberFormat($i18n.locale, {
                          style: 'currency',
                          currency: location.currency,
                          notation: 'compact',
                          compactDisplay: 'short',
                        }).format(location.amount)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="leads-dashboard__network__rank__table__no-data" v-else>
                {{ $t('leads.dashboard.product.noData') }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import DashboardGraph from '@/components/Lead/DashboardGraph.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import DashboardCard from '@/components/Lead/DashboardCard.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'

export default {
  name: 'LeadsList',
  components: {
    DashboardGraph,
    ScreenGrid,
    ScreenCard,
    DashboardCard,
    SkeletonLine,
    UiDropdown,
  },
  data() {
    return {
      statsFilterOptions: [
        { id: 'last1', type: 'day', label: this.$t('leads.dashboard.filters.last1') },
        { id: 'last7', type: 'day', label: this.$t('leads.dashboard.filters.last7') },
        { id: 'last14', type: 'day', label: this.$t('leads.dashboard.filters.last14') },
        { id: 'last30', type: 'day', label: this.$t('leads.dashboard.filters.last30') },
        { id: 'global', type: 'week', label: this.$t('leads.dashboard.filters.global') },
      ],
      overviewGoal: '5',
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    graph: {
      type: Object,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
    overview: {
      type: Object,
      required: true,
    },
    network: {
      type: Object,
      required: true,
    },
    statsFilter: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasOverviewCurrent() {
      return (
        this.overview?.current &&
        Object.keys(this.overview?.current).length > 0 &&
        this.overview?.current?.percent !== null &&
        this.overview?.current?.with_option !== null
      )
    },
    hasOverviewAverage() {
      return (
        this.overview?.average &&
        Object.keys(this.overview?.average).length > 0 &&
        this.overview?.average?.avg !== null &&
        this.overview?.average?.currency !== null &&
        this.overview?.average?.inactive !== null
      )
    },
    hasOverviewWeekly() {
      return (
        this.overview?.weekly &&
        Object.keys(this.overview?.weekly).length > 0 &&
        this.overview?.weekly?.value !== null &&
        this.overview?.weekly?.currency !== null &&
        this.overview?.weekly?.dealers !== null
      )
    },
    hasNetworkTopBusinessMaker() {
      return this.network?.TOP_BUSINESS_MAKER?.length > 0
    },
    hasNetworkTopLocationAccepted() {
      return this.network?.TOP_LOCATION_ACCEPTED?.length > 0
    },
    hasNetworkTopLocationRejected() {
      return this.network?.TOP_LOCATION_REJECTED?.length > 0
    },
    hasNetworkMostIgnoringDealers() {
      return this.network?.MOST_IGNORING_DEALERS?.length > 0
    },
    hasProductTopCategories() {
      return this.network?.TOP_CATEGORIES?.length > 0
    },
    hasProductTopProductAccepted() {
      return this.network?.TOP_PRODUCT_ACCEPTED?.length > 0
    },
  },
  methods: {
    emitNetworkFilterChange(period) {
      this.$emit('statsFilterChanged', period)
      this.$emit('networkFilterChanged', period)
    },
    emitStatsFilterChange(period) {
      this.$emit('statsFilterChanged', period)
      this.$emit('networkFilterChanged', period)
    },
    emitOverviewFilterChanged(e) {
      this.overviewGoal = e
      this.$emit('overviewFilterChanged', e)
    },
  },
}
</script>

<style lang="scss">
.leads-dashboard {
  padding-right: $gutter-mobile;
  padding-left: $gutter-mobile;

  @media (min-width: $screen-sm) {
    padding-right: 0;
    padding-left: 0;
  }

  &__stats {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: $gutter-mobile;

    @media (min-width: $screen-sm) {
      gap: $gutter-tablet;
    }

    @media (min-width: $screen-lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: $screen-xl) {
      gap: $gutter-desktop;
    }

    &__KPIS {
      display: grid;
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 15px;

      &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: $radius-default;
        padding: $gutter-mobile;

        @media (min-width: $screen-md) {
          padding: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          padding: $gutter-desktop;
        }

        &__number {
          font-size: $font-size-xxl;
          font-weight: $font-weight-700;

          @media (min-width: $screen-sm) {
            font-size: $font-size-xxxl;
          }

          @media (min-width: $screen-md) {
            font-size: $font-size-4xl;
          }
        }

        &__title {
          opacity: 0.7;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;

          @media (min-width: $screen-sm) {
            font-size: $font-size-md;
          }

          @media (min-width: $screen-lg) {
            font-size: $font-size-default;
          }
        }

        &--generated {
          background-color: rgba(map-get($dashboard-color-variants, 'generated'), 0.1);
          color: map-get($dashboard-color-variants, 'generated');
        }
        &--accepted {
          background-color: rgba(map-get($dashboard-color-variants, 'accepted'), 0.1);
          color: map-get($dashboard-color-variants, 'accepted');
        }
        &--rejected {
          background-color: rgba(map-get($dashboard-color-variants, 'rejected'), 0.1);
          color: map-get($dashboard-color-variants, 'rejected');
        }
        &--unprocessed {
          background-color: rgba(map-get($dashboard-color-variants, 'unprocessed'), 0.1);
          color: map-get($dashboard-color-variants, 'unprocessed');
        }
      }
    }
  }

  &__overview {
    &__no-data {
      &__title {
        font-size: $font-size-lg;
        font-weight: $font-weight-title;
      }
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: $gutter-mobile * 2;
        margin-bottom: $gutter-mobile;

        &__message {
          font-size: $font-size-md;
        }
      }
    }
  }

  &__network,
  &__product {
    &__filters {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;

      &__action {
        border: 1px solid;
        border-radius: 50px;
        border-color: map-get($dashboard-color-variants, 'purple');
        cursor: pointer;
        padding: 10px 16px;
        white-space: nowrap;
        color: map-get($dashboard-color-variants, 'purple');
        font-size: $font-size-sm;
        user-select: none;

        &[selected='selected'] {
          background-color: map-get($dashboard-color-variants, 'purple');
          color: $white;
        }
      }
    }

    &__rank {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: $gutter-mobile;

      @media (min-width: $screen-md) {
        gap: $gutter-tablet;
      }

      @media (min-width: $screen-lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media (min-width: $screen-xl) {
        grid-auto-rows: repeat(2, minmax(0, 1fr));
        gap: $gutter-desktop;
      }

      &__table {
        &__title {
          font-size: $font-size-md;
          font-weight: $font-weight-title;

          &--top-business {
            color: map-get($dashboard-color-variants, 'blue');
          }
          &--most-active {
            color: map-get($dashboard-color-variants, 'green');
          }
          &--most-rejecting {
            color: map-get($dashboard-color-variants, 'yellow');
          }
          &--most-ignoring {
            color: map-get($dashboard-color-variants, 'red');
          }
          &--top-categories {
            color: map-get($dashboard-color-variants, 'blue');
          }
          &--top-products {
            color: map-get($dashboard-color-variants, 'green');
          }
        }

        &__no-data {
          padding-top: calc(#{$gutter-mobile} + 8px);
          color: $grey-french;
          font-size: $font-size-md;
        }
        table {
          display: block;
          padding-top: $gutter-mobile;
          overflow-x: auto;
          border-spacing: 0;
          white-space: nowrap;

          tbody {
            display: table;
            width: 100%;

            tr {
              padding: $gutter-mobile/2 0;

              &:first-child {
                border-top: none;
              }

              th {
                padding-right: 1.5rem;
                text-align: start;
                text-transform: uppercase;
                line-height: 40px;
                color: $grey-french;
                font-size: $font-size-sm;
                font-weight: $font-weight-title;

                &:last-child {
                  padding-right: 0;
                }
              }

              td {
                border-top: 1px solid var(--border-color);
                padding-right: 1.5rem;
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 40px;

                &:last-child {
                  padding-right: 0;
                }

                &.col-name {
                  & a {
                    transition: opacity $transition-duration-default $transition-effect-default;
                    color: inherit !important;

                    &:hover {
                      opacity: 0.8;
                    }
                  }
                }

                &.col-rank {
                  width: 50px;
                }

                &.col-quantity {
                  width: 100px;
                }

                &.top-business {
                  color: map-get($dashboard-color-variants, 'blue');
                }
                &.most-active {
                  color: map-get($dashboard-color-variants, 'green');
                }
                &.most-rejecting {
                  color: map-get($dashboard-color-variants, 'yellow');
                }
                &.most-ignoring {
                  color: map-get($dashboard-color-variants, 'red');
                }
                &.top-categories {
                  color: map-get($dashboard-color-variants, 'blue');
                }
                &.top-products {
                  color: map-get($dashboard-color-variants, 'green');
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
