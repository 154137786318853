<template>
  <screen-grid class="sales-leads-dashboard">
    <!-- SUMMARY -->
    <screen-card :title="$t('leads.dashboard.summary.title')" :isLoading="isLoading">
      <template #title>
        <skeleton-line v-if="isUpdatingData" height="40px" width="100%" />
        <div v-else class="sales-leads-dashboard__title">
          {{ $t('leads.salesRep.kpi.title') }}
          <span class="sales-leads-dashboard__title__value">
            {{
              numberFormat({
                number: totalLeadsValue,
                style: 'currency',
                currency: kpis.length ? kpis[0].currency : 'USD',
              })
            }}
          </span>
        </div>
      </template>
      <template #header-right>
        <!-- TIME DROPDOWN -->
        <skeleton-line v-if="isLoading" height="44px" width="44px" />
        <LeadSalesRepFilters v-else date-only @onUpdateFilters="onUpdateFilters" />
      </template>
      <template #body>
        <!-- KPIS -->
        <div class="sales-leads-dashboard__stats">
          <div class="sales-leads-dashboard__stats__kpis">
            <template v-if="isLoading">
              <skeleton-line v-for="n in 4" :key="n" height="94px" width="100%" max-width="100%" />
            </template>
            <template v-else>
              <template v-for="kpi in kpis">
                <LeadSalesRepKpi
                  :key="kpi.name"
                  :kpi="kpi"
                  :selected="selected"
                  :is-loading="isLoading || isUpdatingData"
                  @click="setCurrent"
                ></LeadSalesRepKpi>
                <LeadSalesRepMobileList
                  v-if="$vuetify.breakpoint.xsOnly && kpi.name === selected"
                  :key="kpi.name"
                  :leads-mobile="leads"
                  :is-loading="isLoading"
                  class="sales-leads-dashboard__list"
                  @goToLead="goToLead"
                  @dealerContacted="dealerContacted"
                  @statusChanged="statusChanged"
                />
                <div
                  class="sales-leads-dashboard__actions"
                  v-if="$vuetify.breakpoint.xsOnly && kpi.name === selected && leads.length >= 5"
                  :key="kpi.name"
                >
                  <v-btn text color="primary" rounded @click="goToLeads(selected)">
                    {{ $t('leads.salesRep.kpi.cta') }}
                  </v-btn>
                </div>
              </template>
            </template>
          </div>
        </div>
        <LeadSalesRepMobileList
          v-if="$vuetify.breakpoint.smOnly"
          :leads-mobile="leads"
          :is-loading="isLoading"
          class="sales-leads-dashboard__list"
          @goToLead="goToLead"
          @dealerContacted="dealerContacted"
          @statusChanged="statusChanged"
        />
        <v-data-table
          v-else-if="$vuetify.breakpoint.mdAndUp"
          class="sales-leads-dashboard__table"
          :headers="kpiHeaders"
          hide-default-footer
          :items="leads"
          :loading="isLoading || isUpdatingData"
          @click:row="goToLead"
        >
          <template #item.createdAt="{ item }"> {{ timeFromNow(item.createdAt, true) }} </template>

          <template #item.convertedStatus="{ item }">
            <v-chip :color="getLeadColor(item.convertedStatus)" dark small>
              {{ $tc(`leads.status.${item.convertedStatus.toLowerCase()}`, 1) }}
            </v-chip>
          </template>

          <template #item.price="{ item }">
            {{ numberFormat({ number: item.price, style: 'currency', currency: item.currency }) }}
          </template>

          <template #item.locationName="{ item }">
            <code>{{ item.externalId }}</code>
            <div class="text-truncate" style="max-width: 220px">{{ item.locationName }}</div>
          </template>

          <template #item.productName="{ item }">
            <code>{{ item.productId }}</code>
            <div class="text-truncate" style="max-width: 220px">{{ item.productName }}</div>
          </template>

          <template #item.email="{ item }">
            <div class="text-truncate" style="max-width: 200px">{{ item.email }}</div>
          </template>
          <template #item.managementStatus="{ item }" class="tw-text-center">
            <v-icon v-if="item.managementStatus" color="success">
              {{ icons.mdiBellCheckOutline }}
            </v-icon>
            <v-icon v-else color="error">
              {{ icons.mdiBellAlertOutline }}
            </v-icon>
          </template>
          <template #item.actions="{ item }">
            <LeadSalesRepActions
              :item="item"
              :is-updating="updatingLead === item.id"
              @dealerContacted="dealerContacted"
              @statusChanged="statusChanged"
            />
          </template>
        </v-data-table>

        <div class="sales-leads-dashboard__actions" v-if="$vuetify.breakpoint.smAndUp && leads.length >= 5">
          <v-btn text color="primary" rounded @click="goToLeads(selected)">
            {{ $t('leads.salesRep.kpi.cta') }}
          </v-btn>
        </div>
      </template>
    </screen-card>

    <!-- DEALERS -->
    <screen-card :title="$t('leads.dashboard.summary.title')" :isLoading="isLoading">
      <template #title>
        <skeleton-line v-if="isUpdatingData" height="40px" width="100%" />
        <div v-else class="sales-leads-dashboard__title">
          {{ $t('leads.salesRep.dealer.title') }}
          <span class="sales-leads-dashboard__title__value"> {{ totalDealers }}</span>
        </div>
      </template>
      <template #body>
        <!-- KPIS -->
        <div class="sales-leads-dashboard__stats">
          <div class="sales-leads-dashboard__stats__kpis">
            <template v-if="isLoading || isUpdatingData">
              <skeleton-line v-for="n in 4" :key="n" height="94px" width="100%" max-width="100%" />
            </template>
            <template v-else>
              <LeadSalesRepDealer
                v-for="kpi in kpis"
                :key="kpi.name"
                :is-loading="isLoading || isUpdatingData"
                :kpi="kpi"
                @click="goToDealer"
              ></LeadSalesRepDealer>
            </template>
          </div>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { timeFromNow } from '@/utils/hours.util'
import { numberFormat } from '@/utils/utilities.util'
import { getLeadColor } from '@/utils/colors.util'
import { mdiBellAlertOutline, mdiBellCheckOutline, mdiDotsVertical } from '@mdi/js'
import LeadSalesRepMobileList from '@/components/Lead/SalesRep/MobileList.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import LeadSalesRepKpi from '@/components/Lead/SalesRep/Kpi.vue'
import LeadSalesRepDealer from '@/components/Lead/SalesRep/Dealer.vue'
import LeadSalesRepFilters from '@/components/Lead/SalesRep/Filters.vue'
import LeadSalesRepActions from '@/components/Lead/SalesRep/Actions.vue'

export default {
  name: 'LeadsSalesDashboard',
  components: {
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    LeadSalesRepKpi,
    LeadSalesRepDealer,
    LeadSalesRepMobileList,
    LeadSalesRepFilters,
    LeadSalesRepActions,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdatingData: {
      type: Boolean,
      required: false,
      default: false,
    },
    updatingLead: {
      type: [Number, String],
      required: false,
      default: null,
    },
    leads: {
      type: Array,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dealers: [],
      selected: 'pending',
      timeFromNow,
      numberFormat,
      getLeadColor,
      date: [],
      icons: {
        mdiBellAlertOutline,
        mdiBellCheckOutline,
        mdiDotsVertical,
      },
    }
  },
  computed: {
    totalLeadsValue() {
      return this.kpis.reduce((prevValue, currValue) => prevValue + currValue.amount * 1, 0)
    },
    totalDealers() {
      return this.kpis.reduce((prevValue, currValue) => prevValue + currValue.dealers * 1, 0)
    },
    kpiHeaders() {
      return [
        { text: '', value: 'actions', sortable: false },
        {
          text: this.$t('leads.salesRep.column.createdAt'),
          sortable: false,
          value: 'createdAt',
          width: 140,
        },
        { text: this.$t('leads.salesRep.column.status'), sortable: false, value: 'convertedStatus' },
        { text: this.$t('leads.salesRep.column.value'), sortable: false, value: 'price' },
        { text: this.$t('leads.salesRep.column.locationName'), sortable: false, value: 'locationName' },
        { text: this.$t('leads.salesRep.column.productName'), sortable: false, value: 'productName' },
        { text: this.$t('leads.salesRep.column.customer'), sortable: false, value: 'email' },
        {
          text: this.$t('leads.salesRep.column.dealerContacted'),
          sortable: false,
          value: 'managementStatus',
          align: 'center',
        },
      ]
    },
    kpis() {
      if (!this.summary) {
        return []
      }
      return Object.entries(this.summary)
        .filter(([key]) => key !== 'TOTAL')
        .map(([key, value]) => {
          return {
            ...value,
            name: key.toLowerCase(),
            selected: false,
          }
        })
    },
  },
  methods: {
    onUpdateFilters(filters, search, date) {
      this.date = date
      this.stringifiedSearchFilters = filters
      this.loadLeads(
        {
          filters: `convertedStatus:${this.selected.toUpperCase()}`,
          size: 5,
          page: 1,
          from: this.date[0],
          to: this.date[1],
        },
        true,
        true
      )
    },
    setCurrent(name) {
      this.selected = name
      this.loadLeads(
        {
          filters: `convertedStatus:${this.selected.toUpperCase()}`,
          size: 5,
          page: 1,
          from: this.date[0],
          to: this.date[1],
        },
        true,
        true
      )
    },
    loadLeads(tableParams = null, resetLeads = false, updating = false) {
      this.$emit('loadLeads', {
        tableParams,
        resetLeads,
        updating,
      })
    },
    goToLeads(status) {
      this.$router.push({ name: 'Leads', params: { currentTab: 'list' }, query: { status } })
    },
    goToDealer(status) {
      this.$router.push({
        name: 'Leads',
        params: { currentTab: 'dealers' },
        query: { status, date: this.date },
      })
    },
    goToLead(e) {
      if (e.row) {
        this.$router.push({ name: 'Lead', params: { locationId: e.row.locationId, id: e.row.id } })
      } else {
        this.$router.push({ name: 'Lead', params: { locationId: e.locationId, id: e.id } })
      }
    },
    dealerContacted(item) {
      this.$emit('updateLead', item, {
        managementStatus: 'CALL',
        price: item.price,
      })
    },
    statusChanged(item, status) {
      this.$emit('updateLead', item, {
        convertedStatus: status,
        price: item.price,
      })
    },
  },
}
</script>

<style lang="scss">
.sales-leads-dashboard {
  padding-right: $gutter-mobile;
  padding-left: $gutter-mobile;

  @media (min-width: $screen-sm) {
    padding-right: 0;
    padding-left: 0;
  }

  &__title {
    &__value {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  &__stats {
    margin-top: 1rem;

    @media (min-width: $screen-sm) {
      margin-top: 0;
    }

    &__kpis {
      display: grid;
      gap: 1rem;
      width: 100%;

      @media (min-width: $screen-sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1.5rem;
      }
    }
  }

  &__table,
  &__list {
    @media (min-width: $screen-sm) {
      margin-top: 1.5rem;
    }
  }

  &__list {
    margin-right: -1rem;
    margin-left: -1rem;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 1;

    @media (min-width: $screen-sm) {
      margin-top: 1.5rem;
    }
  }
}
</style>
