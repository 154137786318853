<template>
  <v-menu
    offset-y
    left
    :close-on-content-click="true"
    max-width="300"
    min-width="150"
    transition="slide-y-reverse-transition"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn icon v-bind="attrs" v-on="{ ...menu }">
        <v-icon>{{ icons.mdiCalendar }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item link v-for="period in periods" :key="period.id" @click="$emit('setPeriod', period.id)">
        <v-list-item-content>
          <v-list-item-title>{{ period.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'LeadSalesRepDateMenu',
  props: {
    periods: {
      type: Array,
      rquired: true,
    },
  },
  data: () => ({
    icons: {
      mdiCalendar,
    },
  }),
}
</script>
