<template>
  <div>
    <div class="lead-list-filters">
      <div class="lead-list-filters__section">
        <div class="lead-list-filters__section__label">
          {{ $t('leads.list.filters.location.status') }}
        </div>
        <div class="lead-list-filters__section__radios">
          <ui-radio
            class="lead-list-filters__section__radios__radio"
            id="status-all"
            value="ACCEPTED|REJECTED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('leads.list.filters.location.all') }}
          </ui-radio>
          <ui-radio
            class="lead-list-filters__section__radios__radio"
            id="status-accepted"
            value="ACCEPTED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('leads.list.filters.location.accepted') }}
          </ui-radio>
          <ui-radio
            class="lead-list-filters__section__radios__radio"
            id="status-rejected"
            value="REJECTED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('leads.list.filters.location.rejected') }}
          </ui-radio>
        </div>
      </div>

      <div class="lead-list-filters__section">
        <div class="lead-list-filters__section__label">
          {{ $t('leads.list.filters.location.date') }}
        </div>
        <div class="lead-list-filters__section__date-range">
          <ui-date-picker
            v-model="filters.dates"
            :locale="$i18n.locale"
            id="leads-list-date-range-filter"
            :label="[$t('common.date.from'), $t('common.date.to')]"
            design="minimalist"
            mode="range"
          />
        </div>
      </div>

      <div class="lead-list-filters__section">
        <div class="lead-list-filters__section__label">
          {{ $t('leads.list.filters.location.locations') }}
        </div>
        <div class="lead-list-filters__section__locations">
          <v-autocomplete
            v-model="locationIds"
            :items="leadLocations"
            cache-items
            :item-text="item => `${item.name} [${item.externalId}]`"
            item-value="id"
            :label="$t('leads.salesRep.fields.locations.placeholder')"
            :placeholder="$t('leads.salesRep.fields.locations.placeholder')"
            hide-details
            solo
            flat
            outlined
            dense
            clearable
            multiple
            deletable-chips
            chips
            small-chips
            color="primary"
            @blur="onLocationSelection"
          >
          </v-autocomplete>
        </div>
      </div>

      <div class="lead-list-filters__section">
        <div class="lead-list-filters__section__label">
          {{ $t('leads.list.filters.customer.status') }}
        </div>
        <ui-radio
          id="no-feedback"
          class="lead-list-filters__section__customer-radios"
          name="customer-status"
          value="noFeedback"
          align="space-between"
          v-model="filters.customerStatus"
        >
          {{ $t('leads.list.filters.customer.noFeedback') }}
        </ui-radio>

        <ui-radio
          id="satisfied"
          class="lead-list-filters__section__customer-radios"
          name="customer-status"
          value="satisfied"
          align="space-between"
          v-model="filters.customerStatus"
        >
          {{ $t('leads.list.filters.customer.satisfied') }}
        </ui-radio>
        <ui-radio
          id="unsatisfied"
          class="lead-list-filters__section__customer-radios"
          name="customer-status"
          value="unsatisfied"
          align="space-between"
          v-model="filters.customerStatus"
        >
          {{ $t('leads.list.filters.customer.unsatisfied') }}
        </ui-radio>
        <ui-radio
          id="reassign"
          class="lead-list-filters__section__customer-radios"
          name="customer-status"
          value="reassign"
          align="space-between"
          v-model="filters.customerStatus"
        >
          {{ $t('leads.list.filters.customer.reassign') }}
        </ui-radio>
        <ui-radio
          id="noReassign"
          class="lead-list-filters__section__customer-radios"
          name="customer-status"
          value="noReassign"
          align="space-between"
          v-model="filters.customerStatus"
        >
          {{ $t('leads.list.filters.customer.noReassign') }}
        </ui-radio>
      </div>

      <div class="lead-list-filters__section__buttons">
        <div class="lead-list-filters__section__buttons__reset">
          <ui-button button="secondary" icon="reset" :label="$t('locations.list.filters.reset')" @click="reset" />
        </div>
        <div class="lead-list-filters__section__buttons__close">
          <ui-button button="primary" v-close-popover icon="close" :label="$t('locations.list.filters.close')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDatePicker from '@/components/UI/DatePicker.vue'

const getStringifiedStatus = value => {
  switch (value) {
    case 'ACCEPTED':
      return 'locationStatus:ACCEPTED'
    case 'REJECTED':
      return 'locationStatus:REJECTED'
    case 'ACCEPTED|REJECTED':
    default:
      return ''
  }
}

const getStringifiedCustomerStatus = value => {
  switch (value) {
    case 'noFeedback':
      return 'customerStatus:null'
    case 'satisfied':
      return 'customerStatus:(SATISFIED)'
    case 'unsatisfied':
      return 'customerStatus:UNSATISFIED'
    case 'reassign':
      return 'customerStatus:(REASSIGN;TMP_REASSIGN)'
    case 'noReassign':
      return 'customerStatus:WONT_REASSIGN'
    default:
      return ''
  }
}

const defaultFilters = {
  status: 'ACCEPTED|REJECTED',
  customerStatus: '',
  dates: {},
  locationId: [],
}

export default {
  name: 'LeadsListFilter',
  components: {
    UiRadio,
    UiButton,
    UiDatePicker,
  },
  props: {
    change: {
      type: String,
      required: false,
      default: '',
    },
    leadLocations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        ...defaultFilters,
      },
      locationIds: [],
    }
  },
  computed: {
    stringifiedSearch() {
      return [getStringifiedStatus(this.filters.status), this.stringifiedCustomerStatus, this.stringifiedLocationIds]
        .filter(filter => filter && filter.length > 0)
        .join(',')
    },
    stringifiedCustomerStatus() {
      return `${getStringifiedCustomerStatus(this.filters.customerStatus)}`
    },
    stringifiedLocationIds() {
      if (!this.filters.locationId.length) return ''
      return `locationId:(${this.filters.locationId.join(';')})`
    },
    dateRangeFilters() {
      const filters = {}
      if (this.filters.dates?.start instanceof Date) {
        filters.from = this.filters.dates.start.toISOString().split('T')[0]
      }
      if (this.filters.dates?.end instanceof Date) {
        filters.to = this.filters.dates.end.toISOString().split('T')[0]
      }
      return filters
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: 'onUpdateFilters',
    },
  },
  methods: {
    onUpdateFilters() {
      this.$emit('onUpdateFilters', { search: this.stringifiedSearch, ...this.dateRangeFilters })
    },
    reset() {
      this.filters = { ...defaultFilters }
    },
    onLocationSelection() {
      this.filters.locationId = [...this.locationIds]
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-list-filters {
  margin: 0 auto;

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      margin-bottom: 0.5rem;
      font-weight: 600;
    }

    &__customer-radios {
      margin: 2px 0;
    }

    &__radios {
      display: flex;
      justify-content: space-between;
      &__radio {
        &:first-child {
          margin: 0;
          margin-right: 0.5rem;
        }
        &:last-child {
          margin: 0;
          margin-left: 0.5rem;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }

    &__locations {
      max-width: 550px;
    }
  }
}
</style>
